import React from "react";
import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import requests from "../../../services/requests";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

const yup = require("yup");

const ModalAditionalFieldRegister = ({ actionModal, tabModal, setTabModal, openModal, handleSuccess }) => {
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-6/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <button
                        onClick={() => setTabModal("Cadastro de campo adicional")}
                        className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}
                    >
                        Cadastro de campo adicional
                    </button>
                </div>
            }
        >
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        label: "",
                    }}
                    validationSchema={yup.object().shape({
                        label: yup.string().required("Campo obrigatório"),
                    })}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        setSubmitting(true);
                        requests
                            .addAditionalField(values)
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success('Campo "' + response.label + '" cadastrado com sucesso!');
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ handleChange, handleSubmit, isSubmitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="label" className="text-roxo_oficial font-bold">
                                    Título do campo
                                </label>
                                <Input name="label" id="label" onChange={handleChange} value={values.label} />
                                <ErrorMessage component="label" name="label" className="text-red font-light w-full" />
                            </div>
                            <div className="flex justify-end mt-10">
                                <button
                                    className=" bg-roxo_oficial text-white py-2 px-5 rounded-md"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <>
                                            Salvando <ClipLoader size={10} loading={isSubmitting} />
                                        </>
                                    ) : (
                                        <>Salvar</>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};
export default ModalAditionalFieldRegister;
