import React from "react";
import { Field } from "formik";

const Input = ({ id, name, placeholder, type, component, disabled = false }) => {
    return (
        <Field
            disabled={disabled}
            type={type ? type : "text"}
            id={id}
            name={name}
            className="w-full rounded-md border py-1 px-5 border-C2 h-9"
            placeholder={placeholder}
        />
    );
};
export default Input;
